export const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL.replace(/\/+$/, '')
  : 'https://alllinkcms.com';

export const CDN_URL = process.env.REACT_APP_CDN_URL
  ? process.env.REACT_APP_CDN_URL.replace(/\/+$/, '')
  : 'https://cdn.alllinkcms.com';

export const queryKey = {
  register: 'register',
  authToken: 'authToken',
  user: 'user',
  mutation: 'mutation',
  query: 'query',
  login: 'login',
  getCSRFToken: 'getCSRFToken',
  getOrb: 'getOrb',
  inventory: 'inventory',
  webMatchPredict: 'webMatchPredict',
  userLastOrb: 'userLastOrb',
  interactOnX: 'interactOnX',
  sendOtp: 'sendOtp',
};
