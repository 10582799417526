import Axios from 'axios';
import { API_URL } from './config';

const axiosInstance = Axios.create({
  baseURL: API_URL,
  withXSRFToken: true,
  withCredentials: true,

  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-TOKEN',
});

export default axiosInstance;
