import axios from 'axios';
import { StorageInstance } from '../helper/storage';
import { API_URL, queryKey } from '../helper/config';

export const getUserInventory = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.get(`${API_URL}/api/get-user-inventory`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const updateUserInventory = async ({ itemId, quantity }: { itemId: string; quantity: number }) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.post(
    `${API_URL}/api/update-user-inventory`,
    {
      item_id: itemId,
      quantity,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};
