import { LegacyRef, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, Modal, Space, Form, InputRef, Button, Popover } from 'antd';
import { useLogin } from '../../hooks/auth';
import { checkExistEmail, loginByOtp, logout, register, resetPassword } from '../../api';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppContext } from '../../App';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { TUser, validateEmail } from '../../helper/utils';
import { queryKey } from '../../helper/config';
import { StorageInstance } from '../../helper/storage';
import TZEH from '../../../src/assets/images/tzeh.png';

type TRegisterForm = {
  email?: string;
  password?: string;
  otp?: string;
  confirmPassword?: string;
};

type TLoginForm = {
  login_email?: string;
  login_password?: string;
};

type TProps = {
  userInventory?: any;
};

const Header = (props: TProps) => {
  const { userInventory } = props;
  const isFetching = useIsFetching();
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const { setIsAuth, isAuth, setUser, user, isLoading, isAuthLoading, isModalLoginOpen, setIsModalLoginOpen } =
    useAppContext();
  const [loginForm] = Form.useForm<TLoginForm>();
  const [registerForm] = Form.useForm<TRegisterForm>();
  const [sendOtpForm] = Form.useForm<TLoginForm>();
  const location = useLocation();
  const { login, sendOtp } = useLogin();
  const [otpLoading, setOtpLoading] = useState<'success' | 'error' | 'warning' | 'validating' | undefined>(undefined);
  const [isLoginOtpLoading, setIsLoginOtpLoading] = useState<boolean>(false);

  const [isLoginMode, setIsLoginMode] = useState<boolean>(true);
  const [isForgot, setIsForgot] = useState(false);
  const emailRef = useRef<InputRef | null>(null);
  const passwordRef = useRef<InputRef | null>(null);

  const loginOtpEmailRef1 = useRef<InputRef | null>(null);
  const loginOtpEmailRef2 = useRef<InputRef | null>(null);
  const loginOtpOtpRef = useRef<InputRef | null>(null);
  const loginOtpPasswordRef = useRef<InputRef | null>(null);
  const loginOtpPasswordConfirmRef = useRef<InputRef | null>(null);

  const showModal = () => {
    setIsModalLoginOpen(true);
  };
  const handleOk = () => {
    setIsModalLoginOpen(false);
  };
  const handleCancel = () => {
    setIsModalLoginOpen(false);
  };
  const getActivePage = (pageName: string) => {
    if (pageName === location.pathname) {
      return true;
    }
    return false;
  };

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: any) => {
    setOpen(newOpen);
  };

  const onLogout = async () => {
    hide();
    try {
      logout();
    } catch (error) {}
    await StorageInstance.clear();
    setIsAuth(false);
  };

  const afterAuthSuccess = async (response: AxiosResponse) => {
    queryClient.setQueryData<TUser | null>([queryKey.user], (_) => ({ ...response.data.data.user }));
    await StorageInstance.set(queryKey.authToken, response?.data?.data?.access_token);
    await StorageInstance.set(queryKey.authToken, response?.data?.data?.access_token);
    setUser(response?.data?.data?.user);
    setIsAuth(true);
    setIsModalLoginOpen(false);
    setIsAuth(true);
  };

  const checkEmailExists = async (email?: string) => {
    try {
      const response = await checkExistEmail({ email });
      return Promise.resolve();
    } catch (error) {
      if (error instanceof AxiosError) {
        const code = error.response?.data?.code;
        if (code === 3009) {
          return Promise.reject(new Error('Email not available'));
        }
        return Promise.reject(new Error('Something wrong. Please try again'));
      }
      return Promise.reject(new Error('Something wrong. Please try again'));
    }
  };

  const [debouncedCheckEmailExists] = useDebounce(checkEmailExists, 1000);

  const onFinish = async (values: any) => {
    const { login_email, login_password } = values;
    try {
      const response = await login(login_email, login_password);
      await afterAuthSuccess(response);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.errors?.email?.[0] || error.response?.data?.errors?.password?.[0]);
      } else {
        return setError('Something wrong. Please try again');
      }
    }
  };

  const onRegisterFinish = async () => {
    try {
      await registerForm.validateFields();
      const { email, password, otp } = registerForm.getFieldsValue(['email', 'password', 'otp']);
      const response = await register({
        email,
        otp,
        password,
      });
      await afterAuthSuccess(response);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.message);
      } else {
        setError('Something wrong. Please try again');
      }
    }
  };

  const onRegisterChange = (_changedValues: any, _values: any) => {
    setError('');
    registerForm.setFieldsValue(_changedValues);
  };

  const handleSendOtp = async () => {
    if (isForgot) {
      setIsLoginOtpLoading(true);
      if (loginOtpEmailRef1.current?.input?.value) {
        const value = loginOtpEmailRef1.current?.input?.value;
        if (validateEmail(value)) {
          try {
            await sendOtp({ email: value });
            setStep(2);
            setIsLoginOtpLoading(false);
          } catch (error) {
            setIsLoginOtpLoading(false);
            setIsForgot(false);
            setStep(0);
            setOtpLoading('error');
          }
        } else {
          setIsLoginOtpLoading(false);
          return setError('Invalid email format');
        }
      }
      return setIsLoginOtpLoading(false);
    } else {
      if (emailRef?.current?.input?.value) {
        setOtpLoading('validating');
        try {
          await sendOtp({ email: emailRef.current.input?.value });
          setOtpLoading('success');
        } catch (error) {
          setOtpLoading('error');
        }
      }
    }
  };

  const onLoginByOtp = async () => {
    try {
      if (loginOtpOtpRef.current?.input?.value && loginOtpEmailRef2.current?.input?.value) {
        setIsLoginOtpLoading(true);
        const email = loginOtpEmailRef2.current?.input?.value;
        const otp = loginOtpOtpRef.current?.input?.value;
        if (validateEmail(email)) {
          const response = await loginByOtp(email, otp);
          setIsLoginOtpLoading(false);
          await StorageInstance.set(queryKey.authToken, response?.data?.data?.access_token);
          queryClient.setQueryData([queryKey.user], () => ({
            ...response?.data?.data?.user,
          }));
          return setStep(3);
        } else {
          setIsLoginOtpLoading(false);
          setError('Invalid user email or OTP format');
        }
      }
      setIsLoginOtpLoading(false);
      return setError('Invalid email or OTP format');
    } catch (error) {
      return setError('Something wrong. Please try again');
    }
  };

  const onResetPassword = async () => {
    try {
      setIsLoginOtpLoading(true);
      if (loginOtpPasswordConfirmRef.current?.input?.value && loginOtpPasswordRef?.current?.input?.value) {
        const password = loginOtpPasswordRef.current?.input?.value;
        const confirmPassword = loginOtpPasswordConfirmRef.current?.input?.value;
        if (password !== confirmPassword) {
          return setError('Incorrect password form. Please check again');
        }
        const response = await resetPassword(password);
        queryClient.setQueryData([queryKey.user], () => ({
          ...response?.data?.data,
        }));
        setStep(0);
        setIsForgot(false);
        return setIsModalLoginOpen(false);
      }
      return setError('Invalid password field. Please check');
    } catch (error) {
      setIsLoginOtpLoading(true);
      setError('Something wrong here. Please try again');
    }
  };

  const renderModalClaim = () => {
    // Win
    return (
      <div className="win-earned">
        <h3>congratulations! You're earned:</h3>
        <div className="win-earned-claim">
          <img src={TZEH} alt="" className="" />
          <span>x500</span>
        </div>
        <button className="btn btn-claim-note">claim</button>
      </div>
    );
  };

  const renderModalClaimLose = () => {
    // Lose
    return (
      <div className="win-earned">
        <h3>unfortunately, you did not decode the orbs correctly. use another try to test your skills</h3>{' '}
        {/* Lose thì hiện text này */}
        <button className="btn btn-claim-note">try again</button>
      </div>
    );
  };

  const renderModal = () => {
    if (isForgot) {
      return (
        <Form form={sendOtpForm} onValuesChange={() => setError('')}>
          <>
            {step === 1 && (
              <>
                <Form.Item
                  name="otpEmail"
                  rules={[
                    { required: true, message: 'This field is required' },
                    {
                      type: 'email',
                      message: 'Invalid email',
                    },
                  ]}
                >
                  <div className="item">
                    <Input className="txtInput" placeholder="Enter email" ref={loginOtpEmailRef1} />
                  </div>
                </Form.Item>
                <div className="modal-footer">
                  <button
                    className="btn btn-register"
                    onClick={() => {
                      setStep(0);
                      setIsForgot(false);
                      setIsLoginMode(true);
                    }}
                  >
                    Back to Login
                  </button>
                  <button
                    className="btn btn-login btn-loading"
                    onClick={handleSendOtp}
                    disabled={isFetching > 0 || isLoginOtpLoading}
                  >
                    {isLoginOtpLoading ? '...' : 'Send OTP'}
                  </button>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <Form.Item
                  name="loginOtpEmail"
                  rules={[
                    { required: true, message: 'This field is required' },
                    {
                      type: 'email',
                      message: 'Invalid email',
                    },
                  ]}
                >
                  <div className="item">
                    <Input className="txtInput" placeholder="Enter email" ref={loginOtpEmailRef2} />
                  </div>
                </Form.Item>
                <Form.Item name="loginOtpOtp" rules={[{ required: true, message: 'This field is required' }]}>
                  <div className="item">
                    <Input className="txtInput" placeholder="Enter otp" ref={loginOtpOtpRef} />
                  </div>
                </Form.Item>
                <div className="modal-footer">
                  <button
                    className="btn btn-register"
                    onClick={() => {
                      setStep(0);
                      setIsForgot(false);
                      setIsLoginMode(true);
                    }}
                  >
                    Back to Login
                  </button>
                  <button
                    className="btn btn-login"
                    onClick={onLoginByOtp}
                    disabled={isFetching > 0 || isLoginOtpLoading}
                  >
                    {isLoginOtpLoading ? '...' : 'Login by OTP'}
                  </button>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <Form.Item name="loginOtpPassword" rules={[{ required: true, message: 'This field is required' }]}>
                  <div className="item">
                    <Input className="txtInput" placeholder="Enter new password" ref={loginOtpPasswordRef} />
                  </div>
                </Form.Item>
                <Form.Item
                  name="loginOtpPasswordConfirm"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <div className="item">
                    <Input className="txtInput" placeholder="Enter confirm password" ref={loginOtpPasswordConfirmRef} />
                  </div>
                </Form.Item>
                <div className="modal-footer">
                  <button
                    className="btn btn-register"
                    onClick={() => {
                      setStep(0);
                      setIsForgot(false);
                      setIsLoginMode(true);
                    }}
                  >
                    Back to Login
                  </button>
                  disabled={isFetching > 0 || isLoginOtpLoading}
                  <button className="btn btn-login" onClick={onResetPassword}>
                    {isLoginOtpLoading ? '...' : 'Reset password'}
                  </button>
                </div>
              </>
            )}
          </>
        </Form>
      );
    }
    if (isLoginMode) {
      return (
        <Form onFinish={onFinish} form={loginForm} onValuesChange={() => setError('')}>
          <Form.Item
            name="login_email"
            rules={[
              { required: true, message: 'This field is required' },
              {
                type: 'email',
                message: 'Invalid email',
              },
            ]}
          >
            <div className="item">
              <Input className="txtInput" placeholder="Enter email" />
            </div>
          </Form.Item>
          <Form.Item name="login_password" rules={[{ required: true, message: 'This field is required' }]}>
            <div className="item">
              <Input type="password" className="txtInput" placeholder="Enter password" />
            </div>
          </Form.Item>

          <div className="modal-footer">
            <button className="btn btn-register" onClick={() => setIsLoginMode(false)}>
              Register
            </button>
            <button
              className="btn btn-login"
              onClick={() => {
                loginForm.submit();
              }}
              disabled={isFetching > 0}
            >
              Login
            </button>
          </div>
          <Link
            className="btn-link"
            to="#"
            onClick={() => {
              setStep(1);
              setIsForgot(true);
            }}
          >
            Forgot Password?
          </Link>
        </Form>
      );
    }
    return (
      <Form form={registerForm} onFinish={onRegisterFinish} onValuesChange={onRegisterChange}>
        <Form.Item
          name="email"
          validateDebounce={1000}
          rules={[
            { required: true, message: 'This field is required' },
            {
              validator: async (_, value: string) => {
                if (!value) {
                  return Promise.resolve();
                }
                if (!value.match(/^\S+@\S+\.\S+$/)) {
                  return Promise.reject('Invalid email');
                }
                await debouncedCheckEmailExists(value);
              },
            },
          ]}
        >
          <div className="item">
            <Input className="txtInput" placeholder="Enter email" ref={emailRef} />
          </div>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              validator(_, value: string) {
                if (!value) {
                  return Promise.resolve();
                }
                if (value.length < 8 || value.length > 15) {
                  return Promise.reject('Password must be 8-15 characters');
                }
              },
            },
          ]}
        >
          <div className="item">
            <Input type="password" className="txtInput" placeholder="Enter password" ref={passwordRef} />
          </div>
        </Form.Item>
        <Form.Item
          name="otp"
          validateStatus={otpLoading}
          hasFeedback
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <div className="item">
            <Space.Compact className="code">
              <Input type="text" className="txtInput" placeholder="Verification code" />
              <Link className="btn-link" to="#" onClick={handleSendOtp}>
                Send Code
              </Link>
            </Space.Compact>
          </div>
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              validator(_, value: string) {
                if (!value) {
                  return Promise.resolve();
                }
                if (value.length < 8 || value.length > 15) {
                  return Promise.reject('Password must be 8-15 characters');
                }
                if (passwordRef.current?.input?.value !== value) {
                  return Promise.reject(new Error("Confirm password doesn't match"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <div className="item">
            <Input type="password" className="txtInput" placeholder="Confirm password" />
          </div>
        </Form.Item>
        <div className="modal-footer">
          <button className="btn btn-register" onClick={() => setIsLoginMode(true)}>
            Back to Login
          </button>
          <button className="btn btn-login" onClick={onRegisterFinish} disabled={isFetching > 0}>
            Register
          </button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <header>
        <div className="container container-header">
          <div className="header">
            <nav>
              <ul>
                <li className={`${getActivePage('/') ? 'active' : ''}`}>
                  <Link to="/">Home</Link>
                </li>
                <li className={`${getActivePage('/gallery') ? 'active' : ''}`}>
                  <Link to="/gallery">The Game</Link>
                </li>
                <li className={`${getActivePage('/quest') ? 'active' : ''}`}>
                  <Link to="/quest">Quests</Link>
                </li>
                <li className={`${getActivePage('/redeem') ? 'active' : ''}`}>
                  <Link to="/#">Redeem</Link>
                </li>
              </ul>
            </nav>

            {!isAuth && !isLoading && !isAuthLoading && (
              <button onClick={showModal} className="btn-join">
                JOIN HALFWISH
              </button>
            )}
            {(isLoading || isAuthLoading) && (
              <div className="header-right">
                {/* <Popover
                  content={
                    <button className="btn-exit" onClick={onLogout}>
                      Logout
                    </button>
                  }
                  title=""
                  trigger="click"
                  placement="bottomRight"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <div className="login-avatar">
                    <div className="progress">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </Popover> */}
                <div className="login-avatar">
                  <div className="progress">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                {/* <div className="total-amount">
                  <p>0</p>
                  <img src={TZEH} alt="" className="" />
                </div> */}
              </div>
            )}
            {isAuth && user && !isLoading && !isAuthLoading && (
              <div className="header-right">
                <Popover
                  content={
                    <button className="btn-exit" onClick={onLogout}>
                      Logout
                    </button>
                  }
                  title=""
                  trigger="click"
                  placement="bottomRight"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <div className="login-avatar">{user?.email}</div>
                </Popover>
                {(isLoading || isAuthLoading) && <div className="total-amount"></div>}
                {isAuth && !isLoading && !isAuthLoading && (
                  <div className="total-amount">
                    <p> {userInventory?.data?.find((item: any) => item.item_id === 'ITM002')?.quantity || 0}</p>
                    <img src={TZEH} alt="" className="" />
                  </div>
                )}
              </div>
            )}
            {/* <Modal
              centered
              title=""
              className='bg-claim bg-claim-lose' // Win className='bg-claim' còn Los thì className='bg-claim bg-claim-lose'
              footer={false}
              closeIcon={false}
              open={isModalLoginOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              maskClosable={!isForgot}
              destroyOnClose={true}
            >
              {renderModalClaimLose()}
            </Modal> */}

            <Modal
              centered
              title=""
              className={isForgot ? 'bg-login bg-forgot' : 'bg-login'}
              footer={false}
              closeIcon={false}
              open={isModalLoginOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              maskClosable={!isForgot}
              destroyOnClose={true}
            >
              {renderModal()}
              {error && <div style={{ color: 'red' }}>{error}</div>}
            </Modal>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
