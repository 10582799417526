import { AxiosResponse } from 'axios';
import axiosInstance from '../helper/axios-helper';
import { API_URL, queryKey } from '../helper/config';
import { StorageInstance } from '../helper/storage';
import { TUser } from '../helper/utils';

export const register = ({
  email,
  otp,
  password,
  name,
}: {
  email: string;
  otp: string;
  password: string;
  name?: string;
}) => {
  return axiosInstance.post(`/api/register`, { email, otp, password, name });
};

export const requestSendOtp = ({ email }: { email: string }) => {
  return axiosInstance.post(`/api/send-otp`, { email });
};

export const getUserInfo = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  const result: AxiosResponse<TUser> = await axiosInstance.get(`/api/user`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return result.data;
};

export const requestLogin = ({ email, password }: { email: string; password: string }) => {
  return axiosInstance.post(`/api/login`, { email, password });
};

export const linkTwitter = async ({ token }: { token: string }) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axiosInstance.post(
    `/api/link-twitter`,
    { token },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};

export const logout = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axiosInstance.post(
    `/api/logout`,
    {},
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};

export const checkExistEmail = async ({ email }: { email?: string }) => {
  return axiosInstance.get(`${API_URL}/api/check-exist-email?email=${email}`);
};

export const getCSRFCookie = async () => {
  return axiosInstance.get(`/sanctum/csrf-cookie`);
};

export const loginByOtp = async (email: string, otp: string) => {
  return axiosInstance.post(`/api/login-otp`, { email, otp });
};

export const resetPassword = async (newPassword: string) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axiosInstance.post(
    `/api/user`,
    { password: newPassword },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};
