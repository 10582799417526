import { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Gallery from './Gallery';
import axiosInstance from './helper/axios-helper';
import { queryKey } from './helper/config';
import { StorageInstance } from './helper/storage';
import { TUser } from './helper/utils';
import Home from './Home';
import { useAuth } from './hooks/auth';
import Quest from './Quest';
import Redeem from './Redeem';

export interface IAppContext {
  isAuth: boolean;
  setIsAuth: (auth: boolean) => void;
  user: TUser | undefined;
  setUser: (u: TUser) => void;
  isLoading: boolean;
  isAuthLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  isModalLoginOpen: boolean;
  setIsModalLoginOpen: (isModalLoginOpen: boolean) => void;
}

export const AppContext = createContext<IAppContext>({
  isAuth: false,
  setIsAuth: () => {},
  user: undefined,
  setUser: () => {},
  isLoading: false,
  setIsLoading: (i) => {},
  isAuthLoading: false,
  isModalLoginOpen: false,
  setIsModalLoginOpen: (isModalLoginOpen: boolean) => {},
});

export const useAppContext = () => useContext(AppContext);

function App() {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [user, setUser] = useState<TUser | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalLoginOpen, setIsModalLoginOpen] = useState<boolean>(false);
  const { isAuthLoading } = useAuth(setUser, setIsAuth);

  useEffect(() => {
    const getTwitterToken = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = queryParams.get('token');
      if (tokenFromUrl && isAuth) {
        const authToken = await StorageInstance.get(queryKey.authToken);
        try {
          const response = await axiosInstance.post(
            '/api/link-twitter',
            {
              token: tokenFromUrl,
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            },
          );
        } catch (error) {}
      }
    };
    getTwitterToken();
  }, [user, isAuth]);

  return (
    <AppContext.Provider
      value={{
        isAuth,
        setIsAuth,
        user,
        setUser,
        isLoading,
        setIsLoading,
        isAuthLoading,
        isModalLoginOpen,
        setIsModalLoginOpen,
      }}
    >
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/quest" element={<Quest />} />
        {/* <Route path="/redeem" element={<Redeem />} /> */}
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
