export type TUser = {
  id: number;
  name: string;
  email: string;
  twitter_id: string;
  created_at: string;
  updated_at: string;
  halfwish_id: string;
};

export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
};
