import QrCode from '../../assets/images/qr_code.png';

const Social = () => {
  return (
    <div className="halfwish-social">
      <div className="container">
        <div className="halfwish-social-info--content">
          <h2>JOIN OUR COMMUNITY!</h2>
          <ul>
            <li>
              <a href="https://linktr.ee/playhalfwish" rel="noreferrer" target="_blank">
                <img width={'500px'} src={QrCode} alt="QR Code" />
              </a>
            </li>
            {/* <li>
                <a href="https://twitter.com/playhalfwish" rel="noreferrer" target="_blank">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href="#" rel="noreferrer" target="_blank">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://medium.com/@PlayHalfwish" rel="noreferrer" target="_blank">
                  <img src={Medium} alt="Medium" />
                </a>
              </li>
              <li>
                <a href="https://discord.com/invite/M9gCMdbAKU" rel="noreferrer" target="_blank">
                  <img src={Discord} alt="Discord" />
                </a>
              </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Social;
