import { Input, InputRef, Modal, Popover } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Countdown, { CountdownRenderProps, zeroPad } from 'react-countdown';
import { Link } from 'react-router-dom';
import HideBox from '../assets/images/bg_box.svg';
import Decode from '../assets/images/Decode.png';
import Discord from '../assets/images/Discord.svg';
import Facebook from '../assets/images/Facebook.svg';
import Instagram from '../assets/images/Instagram.svg';
import Interact from '../assets/images/Interact.png';
import Interact_Feedback from '../assets/images/Interact_Feedback.png';
import Medium from '../assets/images/Medium.svg';
import Predict from '../assets/images/Predict.png';
import Twitter from '../assets/images/Twitter.svg';
import TZEH from '../assets/images/tzeh.png';
import '../assets/styles/main.css';
import Assassin from '../assets/images/Assassin.png';
import Halfwish_V2 from '../assets/images/Halfwish_V2.png';
import Ignite_V2 from '../assets/images/Ignite_V2.png';
import Repulse from '../assets/images/Repulse.png';
import Knight from '../assets/images/Knight.png';
import Vanguard from '../assets/images/Vanguard.png';
import Ranger from '../assets/images/Ranger.png';

import Header from '../component/Header';

import { AxiosError } from 'axios';
import { guessOrb, requestInviteFriend } from '../api';
import { requestPostWebMatchPrediction } from '../api/match';
import { useAppContext } from '../App';
import { API_URL, CDN_URL, queryKey } from '../helper/config';
import { useInventory } from '../hooks/inventory';
import { useGetLastUserOrb, useInteractOnX, useQuest, useWebMatch } from '../hooks/orb';
import dayjs from 'dayjs';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Social from '../component/Social';
import Footer from '../component/Footer';

const TOTAL_ROW = 5;
const TOTAL_COLUMN = 5;

const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (completed) {
    // Render a completed state
    return <div>The game start</div>;
  } else {
    // Render a countdown
    return <h3>{`PREDICTIONS CLOSE IN ${days}D ${hours}H ${minutes}M`}</h3>;
  }
};

const renderRefreshGuessOrb = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
  if (completed) {
    // Render a completed state
    return <></>;
  } else {
    // Render a countdown
    if (days > 0) return <p>{`REFRESH IN ${days}D ${hours}H ${minutes}M`}</p>;
    return <p>{`REFRESH IN ${hours}H ${minutes}M`}</p>;
  }
};

function Quest() {
  const { isAuth, setIsModalLoginOpen, user } = useAppContext();
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isInPlay, setIsInPlay] = useState<boolean>(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>();
  const [isCodeModalOpen, setIsCodeModalOpen] = useState<boolean>();
  const [isGetNewOrb, setIsGetNewOrb] = useState<boolean>(false);
  const isFetching = useIsFetching();
  const { data, refetch: refetchQuest } = useQuest(isInPlay, isGetNewOrb);
  const { data: post } = useInteractOnX();
  const { data: userLastOrb, refetch: refetchLastOrb } = useGetLastUserOrb();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const { data: webMatch } = useWebMatch();
  const { data: inventory, refetch } = useInventory();
  const emailRef = useRef<InputRef>(null);
  const codeRef = useRef<InputRef>(null);

  const [popOverModal, setPopOverModal] = useState<boolean[]>(
    Array.from({ length: TOTAL_COLUMN * TOTAL_ROW }).map((_) => false),
  );
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [isResult, setIsResult] = useState<boolean>(false);
  const [selectOrb, setSelectOrb] = useState<any[]>(
    Array.from({ length: TOTAL_COLUMN * TOTAL_ROW }).map((_) => undefined),
  );
  const [currentPopIndex, setCurrentPopIndex] = useState<number>(0);
  const [level, setLevel] = useState<number>(0);
  const [isActivate, setIsActivate] = useState<boolean>(false);

  const arr = Array.from({ length: TOTAL_ROW }, () => Array.from({ length: TOTAL_COLUMN }, () => 0));

  const handleOpenChange = (popOverModalIndex: number) => {
    if (!isAuth) {
      return setIsModalLoginOpen(true);
    }
    setCurrentPopIndex(popOverModalIndex);
    setPopOverModal((pre) => {
      const tempArr = pre.map(() => false);
      const currentIndex = pre.findIndex((item) => item === true);
      if (currentIndex !== popOverModalIndex) {
        tempArr[currentIndex] = false;
        tempArr[popOverModalIndex] = true;
      } else {
        tempArr[popOverModalIndex] = !pre[popOverModalIndex];
      }
      return [...tempArr];
    });
  };

  const onSelectOrb = (orbId: string, image: string) => {
    let newSelect: any[] = [];
    if (!isAuth) {
      return setIsModalLoginOpen(true);
    }

    setSelectOrb((pre) => {
      newSelect = [...pre];
      newSelect[currentPopIndex] = {
        ...newSelect[currentPopIndex],
        image,
        orbId,
      };
      return [...newSelect];
    });

    const start = level * TOTAL_ROW;
    const end = level * TOTAL_ROW + TOTAL_COLUMN;
    let totalSelectPerLevel = 0;
    for (let i = start; i < end; i += 1) {
      if (newSelect[i] !== undefined) {
        totalSelectPerLevel += 1;
      }
    }
    if (totalSelectPerLevel === TOTAL_COLUMN) {
      setIsActivate(true);
    } else {
      if (isActivate) {
        setIsActivate(false);
      }
    }

    handleOpenChange(currentPopIndex);
  };

  const onLinkX = async () => {
    if (!isAuth) {
      return setIsModalLoginOpen(true);
    }
    window.location.href = `${API_URL}/auth/twitter`;
  };

  const onGuessOrb = async () => {
    if (!isAuth) {
      return setIsModalLoginOpen(true);
    }
    try {
      const item = [];
      const start = level * TOTAL_ROW;
      const end = level * TOTAL_ROW + TOTAL_COLUMN;
      for (let i = start; i < end; i += 1) {
        item.push(selectOrb[i].orbId);
      }
      const response = isGetNewOrb
        ? await guessOrb(data?.id, item)
        : await guessOrb(userLastOrb?.data?.last_orb?.id, item);
      if (response?.data?.data?.results) {
        await refetch();
        if (level === TOTAL_ROW - 1) {
          setIsFinish(true);
          if (parseInt(response?.data?.data?.is_win || 0) === 1) {
            setIsResult(true);
          } else {
            setIsResult(false);
          }
          setIsResultModalOpen(true);
        }
        setIsActivate(false);

        if (parseInt(response?.data?.data?.is_win || 0) === 1) {
          setIsFinish(true);
          setIsResult(true);
          setIsResultModalOpen(true);
        } else {
          setLevel((pre) => pre + 1);
        }
        const guessResult = response?.data?.data?.results;
        setSelectOrb((pre) => {
          const newSelect = [...pre];
          for (let i = 0; i < TOTAL_COLUMN; i += 1) {
            newSelect[start + i] = {
              ...newSelect[start + i],
              result: guessResult[i],
            };
          }
          return [...newSelect];
        });
        await refetch();
        // await refetchLastOrb();
      }
    } catch (error) {}
  };

  const onClickBeforePlay = () => {
    if (!isInPlay) {
      return setIsInPlay(true);
    }
    return onGuessOrb();
  };

  const selectBox = (index: number) => {
    if (!isInPlay) {
      return <div style={{ color: 'white' }}>Press Play button to start your game</div>;
    }
    const start = level * TOTAL_ROW;
    const end = level * TOTAL_ROW + TOTAL_COLUMN;

    if (start <= index && index < end) {
      if (isGetNewOrb) {
        return (
          <div className="select-box">
            {data?.items?.length > 0 &&
              data?.items?.map((item: any) => {
                return <img key={item.id} src={item.image} onClick={() => onSelectOrb(item.id, item.image)} />;
              })}
          </div>
        );
      } else {
        return (
          <div className="select-box">
            {userLastOrb?.items?.length > 0 &&
              userLastOrb?.items.map((item: any) => {
                return <img key={item.id} src={item.image} onClick={() => onSelectOrb(item.id, item.image)} />;
              })}
          </div>
        );
      }
    }
    return <div style={{ color: 'white' }}>Please complete all items in previous row</div>;
  };

  const renderSelectOrb = (index: number) => {
    const orb = selectOrb[index];
    if (orb?.result === 0) {
      return (
        <div className="open-box open-box-wrong">
          <img src={orb.image} alt="" />
        </div>
      );
    }

    if (orb?.result === 1) {
      return (
        <div className="open-box open-box-correct">
          <img src={orb.image} alt="" />
        </div>
      );
    }
    if (orb?.result === 2) {
      return (
        <div className="open-box open-box-appro">
          <img src={orb.image} alt="" />
        </div>
      );
    }

    return orb.image ? <img src={orb.image} alt="" /> : <></>;
  };

  const onPredictWebMatch = async (matchId: number, resultId: number) => {
    try {
      const response = await requestPostWebMatchPrediction(matchId, resultId);

      setMessage(response.data.data.message || 'Successfully predict this match');
      setIsModalOpen(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        setMessage(error?.response?.data?.message || 'Something wrong. Please try again');
      }
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleEmailModalOk = () => {
    setIsEmailModalOpen(false);
  };

  const renderPoint = () => {
    if (isFetching && isFetching > 0) {
      return (
        <>
          <li className="box-active">
            <div>
              <img src={TZEH} alt="" className="" />
              <span>1000</span>
            </div>
          </li>
          <li>
            <div>
              <img src={TZEH} alt="" className="" />
              <span>800</span>
            </div>
          </li>
          <li>
            <div>
              <img src={TZEH} alt="" className="" />
              <span>500</span>
            </div>
          </li>
          <li>
            <div>
              <img src={TZEH} alt="" className="" />
              <span>300</span>
            </div>
          </li>
          <li>
            <div>
              <img src={TZEH} alt="" className="" />
              <span>200</span>
            </div>
          </li>
        </>
      );
    }
    const getBoxClassName = (index: number) => {
      if (index === level && isResult) {
        return 'box-active win';
      }
      if (index <= level) {
        return 'box-active';
      }
      return '';
    };
    if (isGetNewOrb) {
      if (data?.rewards && data?.rewards?.length > 0)
        return data?.rewards.map((r: any, index: number) => {
          return (
            <li key={r.id} className={getBoxClassName(index)}>
              <div>
                <img src={TZEH} alt="" className="" />
                <span>{r.max_quantity}</span>
              </div>
            </li>
          );
        });
    } else {
      if (userLastOrb?.rewards?.length > 0) {
        return userLastOrb?.rewards?.map((r: any, index: number) => {
          return (
            <li key={r.id} className={getBoxClassName(index)}>
              <div>
                <img src={TZEH} alt="" className="" />
                <span>{r.max_quantity}</span>
              </div>
            </li>
          );
        });
      }
    }
    return (
      <>
        <li className="box-active">
          <div>
            <img src={TZEH} alt="" className="" />
            <span>1000</span>
          </div>
        </li>
        <li>
          <div>
            <img src={TZEH} alt="" className="" />
            <span>800</span>
          </div>
        </li>
        <li>
          <div>
            <img src={TZEH} alt="" className="" />
            <span>500</span>
          </div>
        </li>
        <li>
          <div>
            <img src={TZEH} alt="" className="" />
            <span>300</span>
          </div>
        </li>
        <li>
          <div>
            <img src={TZEH} alt="" className="" />
            <span>200</span>
          </div>
        </li>
      </>
    );
  };

  const renderPlayButton = () => {
    if (userLastOrb?.data?.try_left) {
      const tryLeft = parseInt(userLastOrb?.data?.try_left[0]);
      if (tryLeft === 0)
        return (
          <button className="btn btn-play-lock" disabled={isFetching > 0 || tryLeft === 0}>
            Refreshing
          </button>
        );
    }
    return (
      <button
        className={isFetching > 0 ? 'btn btn-login btn-disable' : 'btn btn-login'}
        onClick={onClickBeforePlay}
        disabled={isFetching > 0}
      >
        {isInPlay ? 'Activate' : 'Play'}
      </button>
    );
  };

  const renderHintInPlay = () => {
    if (isGetNewOrb) {
      {
        return (
          data?.hint?.length > 0 &&
          data?.hint.map((item: any) => {
            return (
              <div className="hint-item" key={Math.random()}>
                <p>{item.quantity}</p>
                <img src={item.image} alt="" />
              </div>
            );
          })
        );
      }
    }
    return (
      userLastOrb?.hint?.length > 0 &&
      userLastOrb?.hint.map((item: any) => {
        return (
          <div className="hint-item" key={Math.random()}>
            <p>{item.quantity}</p>
            <img src={item.image} alt="" />
          </div>
        );
      })
    );
  };

  const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onClickSendEmail = async () => {
    if (emailRef.current?.input?.value) {
      if (validateEmail(emailRef.current?.input?.value)) {
        try {
          const response = await requestInviteFriend(emailRef.current.input.value);
          setCode(response?.data?.data?.invite_code);
          setIsEmailModalOpen(false);
          setIsCodeModalOpen(true);
        } catch (error) {}
      } else {
        setIsEmailError(true);
      }
    }
  };

  const onCloseCodeModal = () => {
    setIsCodeModalOpen(false);
  };

  const renderModalClaim = () => {
    // Win
    let point = 0;
    if (isGetNewOrb) {
      if (data?.rewards && data?.rewards?.length > 0) {
        point = data?.rewards[level].max_quantity;
      }
    } else {
      point = userLastOrb?.rewards[level].max_quantity;
    }
    return (
      <div className="win-earned">
        <h3>congratulations! You're earned:</h3>
        <div className="win-earned-claim">
          <img src={TZEH} alt="" className="" />
          <span>x{point}</span>
        </div>
        <button className="btn btn-claim-note" onClick={refreshQuest}>
          claim
        </button>
      </div>
    );
  };

  const renderModalClaimLose = () => {
    // Lose
    return (
      <div className="win-earned">
        <h3>unfortunately, you did not decode the orbs correctly. use another try to test your skills</h3>{' '}
        {/* Lose thì hiện text này */}
        <button className="btn btn-claim-note" onClick={refreshQuest}>
          try again
        </button>
      </div>
    );
  };

  const refreshQuest = async () => {
    setIsGetNewOrb(true);
    await refetchLastOrb();
    setIsFinish(false);
    setIsResult(false);
    setIsResultModalOpen(false);
    setLevel(0);
    setSelectOrb([]);
  };

  useEffect(() => {
    if (userLastOrb?.data?.last_orb?.id && parseInt(userLastOrb?.data?.last_orb?.is_finish || 0) !== 1) {
      setIsGetNewOrb(false);
      const guessOrbMap = new Map<string, string>();
      for (let i = 0; i < userLastOrb?.items?.length; i += 1) {
        guessOrbMap.set(userLastOrb.items[i].id, userLastOrb.items[i].image);
      }
      if (userLastOrb?.data?.last_orb?.guess_items?.length > 0) {
        console.log('set level ne2', userLastOrb?.data?.last_orb?.guess_items?.length);
        setLevel(userLastOrb?.data?.last_orb?.guess_items?.length);

        const guessLevel = userLastOrb?.data?.last_orb?.guess_items?.length;

        const guessArr = [...selectOrb];
        for (let i = 0; i < guessLevel; i += 1) {
          for (let j = 0; j < TOTAL_COLUMN; j += 1) {
            const index = i * TOTAL_ROW + j;

            guessArr[index] = {
              orbId: userLastOrb?.data?.last_orb?.guess_items[i][j],
              image: guessOrbMap.get(userLastOrb?.data?.last_orb?.guess_items[i][j]),
              result: userLastOrb?.data?.last_orb?.guess_results[i][j],
            };
          }
        }
        setSelectOrb([...guessArr]);
      }
    } else {
      setIsGetNewOrb(true);
      setSelectOrb([]);
      setLevel(0);
      setIsInPlay(false);
    }
  }, [userLastOrb?.data]);

  return (
    <div className="wrapper">
      <Header userInventory={inventory} />
      <Modal centered title="" className="bg-login" footer={false} closeIcon={false} open={isModalOpen} onOk={handleOk}>
        <div className="modal-footer">{message}</div>
        <div className="modal-footer">
          <button className="btn btn-register" onClick={() => setIsModalOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
        centered
        title=""
        width={700}
        className="bg-mail"
        footer={false}
        closeIcon={true}
        open={isEmailModalOpen}
        onOk={handleEmailModalOk}
        closable={true}
        maskClosable={true}
        onCancel={() => setIsEmailModalOpen(false)}
        onClose={() => setIsEmailModalOpen(false)}
      >
        <div className="modal-body">
          <h3>ENTER FRIEND’S EMAIL:</h3>
          <Input
            className="txtInput"
            placeholder="Enter email"
            ref={emailRef}
            autoFocus={true}
            onChange={() => {
              if (isEmailError) setIsEmailError(false);
            }}
          />
          {isEmailError && <div style={{ color: 'red', marginTop: '1rem' }}>Incorrect email format</div>}
          <button className="btn btn-login" onClick={onClickSendEmail}>
            confirm
          </button>
        </div>
      </Modal>
      <Modal
        centered
        title=""
        width={700}
        className="bg-mail bg-code"
        footer={false}
        closeIcon={false}
        open={isCodeModalOpen}
        onOk={handleOk}
      >
        <div className="modal-body">
          <h3>SEND YOUR FRIEND THIS CODE</h3>
          <p>When they create an account, you both will get a free REFRESH!</p>
          <Input className="txtInput" placeholder="Enter Code" value={code} />
          <button className="btn btn-login" onClick={onCloseCodeModal}>
            Close
          </button>
        </div>
      </Modal>
      <Modal
        centered
        title=""
        className={isResult ? 'bg-claim' : 'bg-claim bg-claim-lose'} // Win className='bg-claim' còn Los thì className='bg-claim bg-claim-lose'
        footer={false}
        closeIcon={false}
        open={isResultModalOpen}
        onOk={() => setIsResultModalOpen(false)}
        onCancel={() => setIsResultModalOpen(false)}
        maskClosable={false}
        destroyOnClose={true}
      >
        {isResult ? renderModalClaim() : renderModalClaimLose()}
      </Modal>
      <article>
        <div className="earn">
          <div className="container">
            <img src={TZEH} alt="" className="" />
            <div>
              <h3>
                earn <span>TZEH</span> BY COMPLETING TASKS.
              </h3>
              <p>
                Collect more before the uprising and you might just be rewarded with <span>tokens of appreciation</span>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card-collect">
            <h3>AVAILABLE TASKS</h3>
            <ul className="list-collect card-collect-list">
              <li>
                <a href="#decode">
                  <h5>DECODE</h5>
                  <img src={Decode} alt="" className="" />
                  <div className="collect-col">
                    <p>Complete the daily puzzle as fast as you can!</p>
                    <div className="rewards">
                      <p>REWARDS</p>
                      <div>
                        <img src={TZEH} alt="" className="" />
                        <span>200-1000</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#interact_on_x">
                  <h5>INTERACT</h5>
                  <img src={Interact} alt="" className="" />
                  <div className="collect-col">
                    <p>INTERACT WITH OFFICIAL #HALFWISH POSTS OR MAKE YOUR OWN!</p>
                    <div className="rewards">
                      <p>REWARDS</p>
                      <div>
                        <img src={TZEH} alt="" className="" />
                        <span>200-400</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a href="#showmatch_prediction">
                  <h5>PREDICT</h5>
                  <img src={Predict} alt="" className="" />
                  <div className="collect-col">
                    <p>PREDICT THE OUTCOME OF SHOWMATCHES!</p>
                    <div className="rewards">
                      <p>REWARDS</p>
                      <div>
                        <img src={TZEH} alt="" className="" />
                        <span>1000</span>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className="card-decode-contain" id="decode">
            <div className="card-decode">
              <div className="card-decode-info">
                <h2>DECODE THE orbs</h2>
                {isAuth ? (
                  isInPlay ? (
                    <div className="hint">
                      <p>Hint:</p>
                      {renderHintInPlay()}
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className="hint">
                    <p>Hint:</p>
                    <div className="hint-item">
                      <p>2</p>
                      <img src={Knight} alt="" />
                    </div>
                    <div className="hint-item">
                      <p>1</p>
                      <img src={Vanguard} alt="" />
                      <img src={Ranger} alt="" />
                    </div>
                  </div>
                )}
                {renderPlayButton()}
                {isAuth && userLastOrb?.data?.try_left && (
                  <div className="decode-tries">
                    <h5>Tries Left: {userLastOrb.data?.try_left}</h5>
                    <Countdown
                      date={dayjs().add(userLastOrb?.data?.refresh_in, 'seconds').toDate()}
                      renderer={renderRefreshGuessOrb}
                    />
                  </div>
                )}
                <div className="decode-invite">
                  <button
                    className="btn btn-invite"
                    onClick={() => setIsEmailModalOpen(true)}
                    disabled={isFetching > 0}
                  >
                    Invite
                  </button>
                  <p>INVITE FRIENDS FOR MORE TRIES!</p>
                </div>
              </div>
              <div className="card-decode-img">
                {arr.map((_, i) => {
                  if (isGetNewOrb)
                    return (
                      <React.Fragment key={Math.random()}>
                        <div className={level >= i ? 'card-decode-item item-active-box' : 'card-decode-item'}>
                          {arr[i].map((_, j) => {
                            const index = i * TOTAL_ROW + j;

                            return (
                              <React.Fragment key={Math.random()}>
                                <Popover
                                  arrow={false}
                                  content={() => selectBox(index)}
                                  title=""
                                  trigger="click"
                                  open={popOverModal[index]}
                                  onOpenChange={() => handleOpenChange(index)}
                                >
                                  {selectOrb[index]?.image !== undefined && isAuth ? (
                                    renderSelectOrb(index)
                                  ) : (
                                    <div className="hide-box">
                                      <img src={HideBox} alt="" />
                                    </div>
                                  )}
                                </Popover>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );

                  return (
                    <React.Fragment key={Math.random()}>
                      <div className={i <= level ? 'card-decode-item item-active-box' : 'card-decode-item'}>
                        {arr[i].map((_, j) => {
                          const index = i * TOTAL_ROW + j;
                          return (
                            <React.Fragment key={Math.random()}>
                              <Popover
                                arrow={false}
                                content={() => selectBox(index)}
                                title=""
                                trigger="click"
                                open={popOverModal[index]}
                                onOpenChange={() => handleOpenChange(index)}
                              >
                                {selectOrb[index]?.image !== undefined && isAuth ? (
                                  renderSelectOrb(index)
                                ) : (
                                  <div className="hide-box">
                                    <img src={HideBox} alt="" />
                                  </div>
                                )}
                              </Popover>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div className="card-tzeh">
              <ul>{renderPoint()}</ul>
            </div>
          </div>
          <div className="card card-interact-x" id="interact_on_x">
            <div>
              <h2>INTERACT ON X</h2>
              <p>Interact (like, repost, comment) with the latest post on @PlayHalfwish’s X for 200-400 TZEH</p>
              <p>You can also post on X with #Halfwish, and if you get #vouched by our mods, you gain 1000 TZEH!</p>
              {!user?.twitter_id && (
                <button className="btn btn-linkx" onClick={onLinkX} disabled={isFetching > 0}>
                  Link your X
                </button>
              )}
            </div>
            <div className="card-img card-img-x">
              <a href="#" target="_blank" style={{ margin: 'auto' }}>
                {post?.data?.x_last_status ? (
                  <>
                    <TwitterTweetEmbed tweetId={post?.data?.x_last_status.split('/status/')[1]} />
                  </>
                ) : (
                  <img src={Interact_Feedback} alt="" className="" />
                )}
              </a>
            </div>
          </div>
          <div className="card card-prediction" id="showmatch_prediction">
            <div>
              <h2>SHOWMATCH PREDICTION</h2>
              <p>
                Predict the winner of a showmatch correctly before {webMatch?.data?.close_at || ''} to earn{' '}
                {webMatch?.data?.reward?.max_quantity || ''} TZEH.
              </p>
              <p>The video result will be released on X on {'<DATE>'}</p>
              <p>To qualify for the TZEH, watch the video and comment on the post within 24 hours.</p>
              {!user?.twitter_id && (
                <button className="btn btn-linkx" onClick={onLinkX}>
                  Link your X
                </button>
              )}
            </div>
            {isAuth ? (
              <>
                {webMatch?.data && (
                  <div className="card-img">
                    {webMatch?.data?.close_in && (
                      <Countdown date={dayjs().add(webMatch?.data?.close_in, 'seconds').toDate()} renderer={renderer} />
                    )}
                    webMatch?.data ? (
                    <ul>
                      <li>
                        {webMatch?.data && (
                          <img src={`${CDN_URL}${webMatch?.data?.p1_class[0].image}`} alt="" className="" />
                        )}
                        {webMatch?.data && (
                          <div className="card-img-item">
                            <img src={`${CDN_URL}${webMatch?.data?.p1_powers[0].image}`} alt="" className="" />
                            <img src={`${CDN_URL}${webMatch?.data?.p1_powers[1].image}`} alt="" className="" />
                            <img src={`${CDN_URL}${webMatch?.data?.p1_powers[2].image}`} alt="" className="" />
                          </div>
                        )}
                        {webMatch?.data && (
                          <button
                            className="btn btn-login"
                            onClick={() => onPredictWebMatch(webMatch?.data?.id, 1)}
                            disabled={isFetching > 0}
                          >
                            Select
                          </button>
                        )}
                      </li>
                      {webMatch?.data && (
                        <li>
                          <span className="card-vs">VS</span>
                        </li>
                      )}
                      <li>
                        {webMatch?.data && (
                          <img src={`${CDN_URL}${webMatch?.data?.p2_class[0].image}`} alt="" className="" />
                        )}
                        {webMatch?.data && (
                          <div className="card-img-item">
                            <img src={`${CDN_URL}${webMatch?.data?.p2_powers[0].image}`} alt="" className="" />
                            <img src={`${CDN_URL}${webMatch?.data?.p2_powers[1].image}`} alt="" className="" />
                            <img src={`${CDN_URL}${webMatch?.data?.p2_powers[2].image}`} alt="" className="" />
                          </div>
                        )}
                        {webMatch?.data && (
                          <button
                            className="btn btn-login"
                            onClick={() => onPredictWebMatch(webMatch?.data?.id, 2)}
                            disabled={isFetching > 0}
                          >
                            Select
                          </button>
                        )}
                      </li>
                    </ul>
                    )
                  </div>
                )}
              </>
            ) : (
              <div className="card-img">
                <ul>
                  <li>
                    <img src={Assassin} alt="" className="" />
                    <div className="card-img-item">
                      <img src={Halfwish_V2} alt="" className="" />
                      <img src={Ignite_V2} alt="" className="" />
                      <img src={Repulse} alt="" className="" />
                    </div>
                    <button className="btn btn-login" onClick={() => setIsModalLoginOpen(true)}>
                      Select
                    </button>
                  </li>
                  <li>
                    <span className="card-vs">VS</span>
                  </li>
                  <li>
                    <img src={Assassin} alt="" className="" />
                    <div className="card-img-item">
                      <img src={Halfwish_V2} alt="" className="" />
                      <img src={Ignite_V2} alt="" className="" />
                      <img src={Repulse} alt="" className="" />
                    </div>
                    <button className="btn btn-login" onClick={() => setIsModalLoginOpen(true)}>
                      Select
                    </button>
                  </li>
                </ul>
              </div>
            )}
            {isAuth && !webMatch?.data && (
              <div className="card-coming card-img">
                <h4>COMING SOON!</h4>
              </div>
            )}
          </div>
        </div>
      </article>
      <Social />
      <Footer />
    </div>
  );
}

export default Quest;
