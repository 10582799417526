import axios from 'axios';
import { StorageInstance } from '../helper/storage';
import { API_URL, queryKey } from '../helper/config';

export const requestOrbId = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.get(`${API_URL}/api/get-orbs-id`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const guessOrb = async (id: number, items: string[]) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.post(
    `${API_URL}/api/guess-orbs`,
    {
      id,
      guess_items: items,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};

export const requestInviteFriend = async (email: string) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.post(
    `${API_URL}/api/invite-friend-for-orb`,
    {
      friend_email: email,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};

export const requestGetUserOrbs = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.get(`${API_URL}/api/get-user-orbs`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const requestGetInteractOnX = async () => {
  return axios.get(`${API_URL}/api/get-interact-on-x`);
};
