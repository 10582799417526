import { useQuery } from '@tanstack/react-query';
import { requestGetInteractOnX, requestGetUserOrbs, requestOrbId } from '../api';
import { CDN_URL, queryKey } from '../helper/config';
import { requestGetWebMatchPrediction } from '../api/match';
import { useAppContext } from '../App';

export const useQuest = (isInPlay: boolean, isGetNewOrb: boolean) => {
  console.log('🚀 ~ useQuest ~ isGetNewOrb:', isGetNewOrb);
  console.log('🚀 ~ useQuest ~ isInPlay:', isInPlay);
  const { isAuth } = useAppContext();
  const { data, refetch } = useQuery({
    queryKey: [queryKey.query, queryKey.getOrb],
    queryFn: async () => {
      try {
        const response = await requestOrbId();
        const result = {
          id: response.data?.data?.id,
          items: response.data?.data?.orb_items.map((item: any) => {
            return {
              ...item,
              image: `${CDN_URL}${item.image}`,
            };
          }),
          rewards: response.data?.data?.orb_rewards,
          hint: response.data?.data?.hint.map((item: any) => {
            return {
              ...item,
              image: `${CDN_URL}${item.image}`,
            };
          }),
        };
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    gcTime: 0,
    enabled: isAuth && isGetNewOrb && isInPlay,
  });

  return { data, refetch };
};

export const useWebMatch = () => {
  const { isAuth } = useAppContext();
  const { data } = useQuery({
    queryKey: [queryKey.webMatchPredict, isAuth],
    queryFn: async () => {
      try {
        const response = await requestGetWebMatchPrediction();
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    enabled: isAuth,
    staleTime: 500,
  });
  return { data };
};

export const useGetLastUserOrb = () => {
  const { isAuth } = useAppContext();
  const { data, refetch } = useQuery({
    queryKey: [queryKey.userLastOrb, isAuth],
    queryFn: async () => {
      try {
        const response = await requestGetUserOrbs();
        const result = {
          ...response.data,
          items: response.data?.data?.orb_items?.map((item: any) => {
            const imgUrl = `${CDN_URL}${item.image}`;

            return {
              ...item,
              image: imgUrl,
            };
          }),
          rewards: response.data?.data?.orb_rewards,
          hint: response.data?.data?.hint?.map((item: any) => {
            return {
              ...item,
              image: `${CDN_URL}${item.image}`,
            };
          }),
        };

        console.log('🚀 ~ queryFn: ~ result:', result);
        return result;
        // return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    enabled: isAuth,
    staleTime: 500,
  });
  return { data, refetch };
};

export const useInteractOnX = () => {
  const { data } = useQuery({
    queryKey: [queryKey.query, queryKey.interactOnX],
    queryFn: async () => {
      try {
        const response = await requestGetInteractOnX();
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    gcTime: 0,
  });

  return { data };
};
