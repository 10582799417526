import '../assets/styles/main.css';
import logohalfwish from '../assets/images/logo.png';
import Jinii_Draft_3_4 from '../assets/images/Jinii Draft_3_4.png';
import Instagram from '../assets/images/Instagram.svg';
import Twitter from '../assets/images/Twitter.svg';
import Facebook from '../assets/images/Facebook.svg';
import Discord from '../assets/images/Discord.svg';
import Medium from '../assets/images/Medium.svg';
import QrCode from '../assets/images/qr_code.png';
import videos from '../assets/video/New_MM_Loop.mp4';
import Header from '../component/Header';
import { useInventory } from '../hooks/inventory';
import Social from '../component/Social';
import Footer from '../component/Footer';

function Home() {
  const { data: inventory, refetch } = useInventory();

  return (
    <div className="wrapper">
      <Header userInventory={inventory} />
      <div className="halfwish-banner">
        {/* <img src={Jinii_Draft_3_4} alt=""  /> */}
        <video autoPlay loop muted playsInline id="myVideo" src={videos}></video>

        <div className="info-banner">
          <div>
            <img src={logohalfwish} alt="" className="img-logo" />
            <p>DRAFT, ADAPT, DECEIVE</p>
          </div>
        </div>
      </div>
      <div className="halfwish-do">
        <div className="container">
          <div className="halfwish-do-info">
            <div className="halfwish-do-info--video">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/bmcQzBIOEFM"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
            <div className="halfwish-do-info--content">
              <h2>What is halfwish?</h2>
              <p>
                Made by card game veterans and pros, this PVP deckbuilder test your ability to bluff, make quick choices
                and draft your wincons on the go.
              </p>
              <p>no buying card packs, no pre-game deck building, and most importantly - no netdeckers.</p>
              <p>It’s the card game you’’ve been waiting for!</p>
            </div>
          </div>
        </div>
      </div>
      <Social />
      <Footer />
    </div>
  );
}

export default Home;
