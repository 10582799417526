const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer">
          <p>©Halfwish 2024 All rights reserved.</p>
          <ul>
            <li>
              <a
                href="https://playhalfwish.gitbook.io/halfwish-website-legal/terms-and-conditions"
                rel="noreferrer"
                target="_blank"
              >
                TERMS OF USE
              </a>
            </li>
            <li>
              <a
                href="https://playhalfwish.gitbook.io/halfwish-website-legal/privacy-policy"
                rel="noreferrer"
                target="_blank"
              >
                PRIVACY POLICY
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
