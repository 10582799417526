import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../helper/config';
import { getUserInventory } from '../api';
import { handleQueryError } from './auth';
import { useAppContext } from '../App';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const useInventory = () => {
  const { isAuth } = useAppContext();
  const location = useLocation();
  const { data, refetch } = useQuery({
    queryKey: [queryKey.user, queryKey.inventory],
    enabled: isAuth,
    queryFn: async () => {
      try {
        const response = await getUserInventory();
        return response.data;
      } catch (error: any) {
        return handleQueryError(error);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  return { data, refetch };
};
