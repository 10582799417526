import { clear, createStore, get, set, update, UseStore } from 'idb-keyval';

export class Storage {
  private static instance: Storage | null = null;
  private storeInstance: UseStore;

  private constructor(dbName: string, dbStore: string) {
    this.storeInstance = createStore(dbName, dbStore);
  }

  public static getInstance(dbName: string, dbStore: string): Storage {
    if (!Storage.instance) {
      Storage.instance = new Storage(dbName, dbStore);
    }
    return Storage.instance;
  }

  public async get(key: string) {
    return get(key, this.storeInstance);
  }

  public async update(key: string, newValue: any) {
    return update(key, (oldValue) => ({ ...oldValue, newValue }), this.storeInstance);
  }

  public async set(key: string, value: string | object | number) {
    return set(key, value, this.storeInstance);
  }

  public async clear() {
    return clear(this.storeInstance);
  }
}

export const StorageInstance = Storage.getInstance('halfwish-db', 'halfwish-store');
