import axios from 'axios';
import { StorageInstance } from '../helper/storage';
import { API_URL, queryKey } from '../helper/config';

export const requestGetWebMatchPrediction = async () => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.get(`${API_URL}/api/get-web-match-prediction`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
};

export const requestPostWebMatchPrediction = async (matchId: number, resultId: number) => {
  const authToken = await StorageInstance.get(queryKey.authToken);
  if (!authToken) {
    throw new Error('Unable to get your auth token');
  }
  return axios.post(
    `${API_URL}/api/select-web-match-prediction`,
    {
      web_match_prediction_id: matchId,
      result: resultId,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    },
  );
};
