import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { getCSRFCookie, getUserInfo, requestLogin, requestSendOtp } from '../api';
import { queryKey } from '../helper/config';
import { StorageInstance } from '../helper/storage';
import { TUser } from '../helper/utils';
import { useAppContext } from '../App';

export const handleQueryError = (error: unknown, defaultMessage: string = 'Something wrong. Please try again') => {
  if (error instanceof AxiosError) {
    throw error;
  }
  if (error instanceof Error) {
    throw error;
  }
  throw new Error(defaultMessage);
};

export const useUser = () => {
  const { data, isFetched, isLoading } = useQuery<TUser | null>({
    queryKey: [queryKey.user],
    queryFn: async () => {
      try {
        await getCSRFCookie();
        const user = await getUserInfo();
        return user;
      } catch (error: any) {
        return handleQueryError(error);
      }
    },
  });
  return { user: data, isFetched, isLoading };
};

export const useAuth = (setUser: any, setIsAuth: any) => {
  const { user, isFetched, isLoading } = useUser();
  const queryClient = useQueryClient();
  const { setIsLoading } = useAppContext();
  setIsLoading(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (isFetched) {
        if (user && user.email) {
          setUser(user);
          setIsAuth(true);
          setIsLoading(false);
        } else {
          setIsAuth(false);
          setIsLoading(false);
          queryClient.setQueryData<TUser | null>([queryKey.user], (_) => null);
          await StorageInstance.clear();
        }
      }
    };
    checkAuth();
  }, [isFetched, user]);
  return { user, isAuthLoading: isLoading };
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationKey: [queryKey.mutation, queryKey.login],
    mutationFn: ({ email, password }: { email: string; password: string }) => requestLogin({ email, password }),
    gcTime: 0,
  });

  const login = async (email: string, password: string) => {
    const response = await mutateAsync({
      email,
      password,
    });
    if (response?.data?.data?.user && response?.data?.data?.access_token) {
      await StorageInstance.set(queryKey.authToken, response?.data?.data?.access_token);
      queryClient.setQueryData([queryKey.user], () => ({
        ...response?.data?.data?.user,
      }));
    }
    return response;
  };

  const { mutateAsync: mutateSendOtp } = useMutation({
    mutationKey: [queryKey.mutation, queryKey.sendOtp],
    mutationFn: ({ email }: { email: string }) => requestSendOtp({ email }),
    gcTime: 0,
  });

  const sendOtp = async ({ email }: { email: string }) => {
    const response = await mutateSendOtp({ email });
    return response.data;
  };

  return { login, sendOtp };
};
