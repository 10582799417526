import ArrowSeasonalLootPool from '../assets/images/arrow_seasonal_lootpool.png';
import Assassin_Class from '../assets/images/Assassin_Class.png';
import banner from '../assets/images/banner.png';
import Combat_Mockup from '../assets/images/Combat_Mockup.png';
import Discord from '../assets/images/Discord.svg';
import Djinn from '../assets/images/Djinn.png';
import Djinn_Faceless from '../assets/images/Djinn_Faceless.png';
import EQ_V2 from '../assets/images/EQ_V2.png';
import Facebook from '../assets/images/Facebook.svg';
import Gear_1 from '../assets/images/gear_1.png';
import Gear_2 from '../assets/images/gear_2.png';
import Halfwish_V2 from '../assets/images/Halfwish_V2.png';
import Icebeam_V2 from '../assets/images/Icebeam_V2.png';
import Ignite_V2 from '../assets/images/Ignite_V2.png';
import img_adapt_outwit from '../assets/images/img_adapt_outwit.png';
import img_collect_champions from '../assets/images/img_collect_champions.png';
import img_explore_and_trade from '../assets/images/img_explore_and_trade.png';
import img_hero from '../assets/images/img_hero.png';
import Instagram from '../assets/images/Instagram.svg';
import Knight_Class from '../assets/images/Knight_Class.png';
import Locations from '../assets/images/Locations.png';
import Medium from '../assets/images/Medium.svg';
import Mimic_Treasure from '../assets/images/Mimic_Treasure.png';
import Precious_Ornament_Treasure from '../assets/images/Precious_Ornament_Treasure.png';
import Ranger_Class from '../assets/images/Ranger_Class.png';
import Repulse from '../assets/images/Repulse.png';
import Rolling_Stone_Hazard from '../assets/images/Rolling_Stone_Hazard.png';
import SeasonalLootPool_1 from '../assets/images/Seasonal_Lootpool_1.png';
import SeasonalLootPool_2 from '../assets/images/Seasonal_Lootpool_2.png';
import The_Gardens_Adventure from '../assets/images/The_Gardens_Adventure.png';
import Titan_Class from '../assets/images/Titan_Class.png';
import Twitter from '../assets/images/Twitter.svg';
import Upgrade_1 from '../assets/images/Upgrade_1.png';
import Upgrades_2 from '../assets/images/Upgrades_2.png';
import Vanguard_Class from '../assets/images/Vanguard_Class.png';
import '../assets/styles/main.css';
import Footer from '../component/Footer';

import Header from '../component/Header';
import Social from '../component/Social';

function Gallery() {
  return (
    <div className="wrapper">
      <Header />
      <article>
        <div className="gallery-banner">
          <div className="container banner-Djinn_Faceless">
            <div className="banner-Djinn_Faceless-img">
              <img src={Djinn_Faceless} alt="" className="img-Djinn_Faceless" />
              <div className="banner-Djinn_Faceless-content">
                <h3>
                  YOU are A <span>DJINN.</span>
                </h3>
                <div className="info">
                  <p>FACELESS, SERVING AT THE WHIM OF MORTALS...</p>
                  <h4>NOT ANYMORE.</h4>
                </div>
              </div>
            </div>
            <ul className="list-collect">
              <li>
                <img src={img_collect_champions} alt="" className="" />
                <h5>Collect CHAMPIONS</h5>
                <p>BUILD THEM YOUR WAY</p>
              </li>
              <li>
                <img src={img_adapt_outwit} alt="" className="" />
                <h5>ADAPT & OUTWIT</h5>
                <p>NO NETDECKING IN THIS GAME</p>
              </li>
              <li>
                <img src={img_explore_and_trade} alt="" className="" />
                <h5>EXPLORE & TRADE</h5>
                <p>DISCOVER MEANINGFUL LOOT TO TRADE</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container container-gallery">
          <div className="card">
            <h2>HEROES</h2>
            <p>
              Heroes are sorted into one of five classes. Heroes have a Unique Power while each class has its own
              starting deck and unique bonus.
            </p>
            <div className="card-img card-img-hero">
              <img src={img_hero} alt="" className="" />
              <ul>
                <li>
                  <img src={Titan_Class} alt="" className="" />
                </li>
                <li>
                  <img src={Knight_Class} alt="" className="" />
                </li>
                <li>
                  <img src={Ranger_Class} alt="" className="" />
                </li>
                <li>
                  <img src={Assassin_Class} alt="" className="" />
                </li>
                <li>
                  <img src={Vanguard_Class} alt="" className="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <h2>GEAR</h2>
            <p>Swaps cards in the starting deck of a hero. Earn, buy or trade for these!</p>
            <div className="card-img card-img-gear">
              <img src={Gear_1} alt="" className="" />
              <img src={Gear_2} alt="" className="" />
            </div>
          </div>
          <div className="card">
            <h2>POWERS</h2>
            <p>Powerful hidden abilities used to swing the tides of duels. Pick 3 before your match!</p>
            <div className="card-img card-img-powers">
              <ul>
                <li>
                  <img src={Halfwish_V2} alt="" className="" />
                </li>
                <li>
                  <img src={Ignite_V2} alt="" className="" />
                </li>
                <li>
                  <img src={Repulse} alt="" className="" />
                </li>
                <li>
                  <img src={EQ_V2} alt="" className="" />
                </li>
                <li>
                  <img src={Icebeam_V2} alt="" className="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <h2>DECEPTION-BASED COMBAT</h2>
            <p>Your hidden dice roll determines card effectiveness - bluff to win!</p>
            <div className="card-img card-img-deception">
              <img src={Combat_Mockup} alt="" className="" />
              <ul>
                <li>
                  <img src={Mimic_Treasure} alt="" className="" />
                </li>
                <li>
                  <img src={Djinn} alt="" className="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <h2>UPGRADES</h2>
            <p>Draft upgrades for basic cards each game to fit your win condition.</p>
            <div className="card-img card-img-upgrades">
              <img src={Upgrade_1} alt="" className="" />
              <img src={Upgrades_2} alt="" className="" />
            </div>
          </div>
          <div className="card">
            <h2>LOCATIONS</h2>
            <p>Neutral events that affect how every game plays out. New Biomes come with new locations!</p>
            <div className="card-img card-img-locations">
              <img src={Locations} alt="" className="" />
              <ul>
                <li>
                  <img src={The_Gardens_Adventure} alt="" className="" />
                </li>
                <li>
                  <img src={Precious_Ornament_Treasure} alt="" className="" />
                </li>
                <li>
                  <img src={Rolling_Stone_Hazard} alt="" className="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="card">
            <h2>SEASONAL LOOTPOOL</h2>
            <p>
              Every season’s biome has a unique lootpool of heroes and items that become increasingly scarce after it
              ends. Trade your spares to future newcomers!
            </p>
            <div className="card-img card-img-seasonal">
              <ul>
                <li>
                  <img src={SeasonalLootPool_1} alt="" className="" />
                </li>
                <li>
                  <img src={ArrowSeasonalLootPool} alt="" className="" />
                </li>
                <li>
                  <img src={SeasonalLootPool_2} alt="" className="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
      <Social />
      <Footer />
    </div>
  );
}

export default Gallery;
